<button
    mat-raised-button
    color="primary"
    [disabled]="disable"
    (click)="onFilesSelectClick()"
    [attr.aria-label]="translationKey | translate">
        <mat-icon class="material-icons-outlined">folder_open</mat-icon>
        {{ translationKey | translate }}
</button>
<input
    #fileInput
    hidden
    class="form-control"
    data-test-id="fileUpload"
    type="file"
    [multiple]="multiple"
    (change)="this.onFilesSelect($event)">
