import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

export type SelectedFileListData = {
  selectedFileList: FileList;
  selectedFileListCount: number;
};

@Component({
  selector: 'nxt-choose-files-button',
  templateUrl: './choose-files-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButton, MatIcon, TranslateModule],
})
export class ChooseFilesButtonComponent {
  @Input({ transform: booleanAttribute }) multiple = false;
  @Input() translationKey = 'COMMON.CHOOSE_FILES';
  @Input() disable = false;
  @Output() selectedFileList = new EventEmitter<SelectedFileListData>();

  @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;

  onFilesSelectClick() {
    this.fileInput?.nativeElement.click();
  }

  onFilesSelect($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    this.selectedFileList.emit({
      selectedFileList: inputElement.files!,
      selectedFileListCount: inputElement.files!.length,
    });

    inputElement.value = '';
  }
}
